import './css/matches.css';
import './App.css';
import { Component } from 'react';
import Player from './Components/Player';
import Match from './Components/Match';

import { instructorInfo } from './instructor-info';

class App extends Component {

  state = {
    player: '',
  }

  targetURL = 'https://92f90kftpf.execute-api.us-east-2.amazonaws.com/prod/match';

  players = ["RedSpinShoes", "Dogmomof7", "iRideThisShit", "TheMindyProject", "Maybelle2", "ErinRM", "MyDogJake"];

  numPlayers = this.players.length;

  matches = [];

  componentDidMount() {

    fetch(`${this.targetURL}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      .then(response => response.json())
      .then(response => {
        this.matches = response.Items;


        // comment this section out for game.  Only used to clear / change instructor info.

        // this.matches.forEach(instructor => {
        //   instructor.playersCompleted = [];

        //   for (let instructorCount = 0; instructorCount < instructorInfo.length; instructorCount++) {
        //     console.table(instructorInfo[instructorCount]);
        //     if (instructor.id === instructorInfo[instructorCount].id) {
        //                 instructor.image_url = instructorInfo[instructorCount].image;
        //     }
        //   }
        //   console.table(instructor);
        //   this.saveUpdate(instructor, "instructor");
        // })
        //  End of instructor changes

        this.setState({ player: '' });
      })
      .catch(err => {
        console.log(err);
      })
  }

  playerSelected = (playerName) => {
    this.matches = this.matches.map(match => {
      let mapMatch = match;
      delete mapMatch.playerAvail;

      if (match.playersCompleted.find(player => player === playerName)) {
        mapMatch["playerAvail"] = true;
      }
      return mapMatch;
    })
    this.setState({ player: playerName });
  }

  saveUpdate = (match, playerAvail) => {

    delete match.playerAvail;
    fetch(`${this.targetURL}/${match.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(match),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      .catch(err => {
        console.log(err);
      })

    if (!playerAvail) match["playerAvail"] = true;

    let matchIndex = this.matches.indexOf(match.id);
    if (matchIndex >= 0) this.matches[matchIndex] = match;
  }


  render() {
    return (
      <div className="App">
        <div className='row'>
          {this.players.sort((player1, player2) => {
            if (player1.toLowerCase() < player2.toLowerCase())
              return -1;
            else return 1;
          })
            .map(player =>
              <Player key={player} name={player} selectedPlayer={this.state.player} playerSelected={this.playerSelected} />)}
        </div>
        <p>For our December challenge, it's the  "59 Instructor of Peloton".  Complete a class of any type with an instructor, and then add yourself to their square.
        </p>
        <p>Backgrounds change color when 80% of us have completed the square.  </p>
        <div className='title'>59 Instructors of Peloton</div>
        <div>

          
          {this.matches.sort((match1, match2) => {
            if (match1.name < match2.name)
              return -1
            else return 1
          })
          .map((match, x) =>
              <Match key={match.id} match={match} selectedPlayer={this.state.player} saveUpdate={this.saveUpdate} numPlayers={this.numPlayers} x={x}/>)}
        </div>

        <ul>
          <li>Select your leaderboard name from the list at the top - it will be highlighted when selected.</li>
          <li>Then, select squares that you have completed.  For each square selected, your leaderboard name will be added and the background color will change.</li>
          {/* <li>Remember, only mark 1 square per class even though a class may
            fit the criteria for several squares.  (You may take the same class multiple times and mark off a square each time.)</li> */}
        </ul>

      </div>

    );
  }
}

export default App;
